import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { HOME_URL, STAFF_UI_URL, STUDENT_URL } from "constants/index";
import { MaterialUIControllerProvider } from "context";
import { ACCESS_TOKEN, USER_TYPE } from "constants";
import { SnackbarProvider } from "notistack";

let data = null;
const url = new URL(window.location.href);
const jwt = url.searchParams.get("action");
const reason = url.searchParams.get("reason");

if (
  jwt === "logout" ||
  url.searchParams.get("continue") === "identity-redirect"
) {
  localStorage.clear();
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(USER_TYPE);
  if(reason==="anotherBrowser"){
    window.location.href = HOME_URL+"/?reason=anotherBrowser";
  }
  else{
    window.location.href = HOME_URL;
  }

}
{
  const type = localStorage.getItem(USER_TYPE);

  if (type === "STAFF") {
    window.location.href = `${STAFF_UI_URL}/dashboard`;
  } else if (type === "STUDENT") {
    window.location.href = `${STUDENT_URL}/dashboard`;
  }
}

data = jwt;
ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <App jwt={data} reason={reason} />
      </SnackbarProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
