import { useEffect, useRef, useState } from "react";
// react-router-dom components
import {
  ArrowBack,
  ArrowForwardIos,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ucuLogo from "assets/img/uculogotousenobg.png";
import { ACCESS_TOKEN, USER_TYPE } from "constants";
import { STAFF_UI_URL, STUDENT_URL } from "constants/index";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router-dom";
import { ClapSpinner } from "react-spinners-kit";
import {
  addToken,
  checkUserName,
  doneSettingUpAuthApp,
  login,
  mfaSetup,
  modifyUser2FA,
  verifyOTPToken,
} from "util/APIUtils";
// @mui material components
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Button from "components/CustomButtons/Button";
import "./input.css";
// @mui icons

import nobg from "assets/img/uculogotousenobg.png";

import MDBox from "components/MDBox";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { deepPurple } from "@mui/material/colors";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import bgImage from "assets/img/loginBg3.png";
import MDButton from "components/MDButton";
import { getInitials, stringToHslColor } from "constants/methodConstants";
import { differenceInDays } from "date-fns";
import { useSnackbar } from "notistack";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label": {
              color: "var(--TextField-brandBorderHoverColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "var(--TextField-brandBorderColor)",
            "&:before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

function Basic(props) {
  const chartRef = useRef(null);
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [showClap, setShowClap] = useState(false);
  const [password, setPassword] = useState("");
  const [next, setNext] = useState(false);
  const [showTimer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setAPIResponse] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [show2FNotificationDialog, setShow2FNotificationDialog] =
    useState(false);

  const [otp, setOTP] = useState("");
  const [showQr, setShowQr] = useState(false);
  const [showOTPVerification, setOTPVerification] = useState(false);

  const [isFirstTime, setIsFirstTime] = useState(false);
  const [twoFactorObject, setTwoFactorObject] = useState({
    qrCode: "",
    mfaCode: "",
  });

  function profilePictureFallBack(fullName) {
    return (
      <Avatar
        alt="Profile Picture"
        style={{
          width: "60px",
          height: "60px",
          border: "3px solid #fff",
          borderRadius: "150px",
          color: (theme) => theme.palette.getContrastText(deepPurple[500]),
          backgroundColor:
            fullName !== undefined &&
            fullName.toUpperCase() !== "NONE" &&
            stringToHslColor(fullName, 52, 47),
        }}
      >
        <h3>
          {fullName !== undefined &&
            fullName.toUpperCase() !== "NONE" &&
            getInitials(fullName)}
        </h3>
      </Avatar>
    );
  }

  const { enqueueSnackbar } = useSnackbar();
  function openSnackbar(message, color) {
    enqueueSnackbar(message, { variant: color });
  }

  const confirm = useConfirm();
  const outerTheme = useTheme();

  useEffect(() => {
    // document.addEventListener("keydown", keyPress, false);
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
  }, []);

  function addTokenFunc(response, userName) {
    setShowClap(true);
    const dataShow = { accessToken: response.accessToken, userName };
    const type = response.userType;

    addToken(dataShow)
      .then(() => {
        localStorage.setItem(USER_TYPE, type);
        localStorage.removeItem(ACCESS_TOKEN);

        if (type === "STUDENT") {
          const studentUrl = `${STUDENT_URL}/?user=${userName}`;
          window.location.replace(studentUrl);
        } else if (type === "STAFF") {
          const staffUrl = `${STAFF_UI_URL}/?user=${userName}`;
          window.location.replace(staffUrl);
        }
      })
      .catch((error) => {
        openSnackbar(error.message, "error");
        setShowClap(false);
      });
  }

  function tokenVerification() {
    setIsLoading(true);

    const addResidenceRequest = {
      otpCode: otp,
      usernameOrEmail,
    };
    verifyOTPToken(addResidenceRequest)
      .then(() => {
        addTokenFunc(apiResponse, usernameOrEmail);
      })
      .catch((error) => {
        openSnackbar(error.message, "error");
        setIsLoading(false);
      });
  }

  function handleSubmitUserName(event) {
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);

    if (event !== undefined) {
      event.preventDefault();
    }

    const loginRequest = {
      usernameOrEmail,
    };
    if (usernameOrEmail === "") {
      openSnackbar("Please first fill in your username or email", "error");
    } else {
      setShowClap(true);
      // if (connection) {
      checkUserName(loginRequest)
        .then((response) => {
          if (response.success) {
            setNext(true);
            setUsernameOrEmail(usernameOrEmail);
          }
          setShowClap(false);
        })
        .catch((error) => {
          openSnackbar(error.message, "error");
          setShowClap(false);
        });
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function loadFactor(email) {
    const addResidenceRequest = {
      usernameOrEmail: email,
    };
    setIsLoading(true);
    mfaSetup(addResidenceRequest)
      .then((response) => {
        setTwoFactorObject(response);
        setIsLoading(false);
      })
      .catch((error) => {
        openSnackbar(error.message, "error");
        setIsLoading(false);
      });
  }

  function sendDoneWith(email) {
    confirm({
      description:
        "Be sure to have first scanned the QR code before you confirm that you are done scanning. The code WILL NOT appear again if you confim without scanning it first and you WILL NOT be able to sign in!",
    })
      .then(() => {
        /* ... */

        const addResidenceRequest = {
          usernameOrEmail: email,
        };
        setIsLoading(true);
        doneSettingUpAuthApp(addResidenceRequest)
          .then(() => {
            setIsLoading(false);
            setIsFirstTime(false);
            setOTPVerification(true);
          })
          .catch((error) => {
            setIsLoading(false);
            openSnackbar(error.message, "error");
          });
      })
      .catch(() => {
        /* ... */
      });
  }

//   function  keyPress(e){
//     if(e.keyCode == 13 && showOTPVerification){
//       // console.log('value', e.target.value);
//       alert("hey")
//        // put the login here
//       //  this.handleSubmit();
//     }
//  }

  const handleLogin = (event) => {
    // chartRef.current.focus();
    event.preventDefault();
    setShowClap(true);
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);

    const loginRequest = {
      usernameOrEmail,
      password,
    };

    login(loginRequest)
      .then((response) => {
        setAPIResponse(response);
        if (response.success === false) {
          openSnackbar("Invalid Login credentials", "error");
        } else if (response.using2FA) {
          setShowQr(true);
          setIsFirstTime(!response.alreadySetUp);
          setOTPVerification(response.alreadySetUp);
          // chartRef.current.focus();
          if (!response.alreadySetUp) {
            loadFactor(usernameOrEmail);
          }
        } else if (response.userType === "STAFF") {
          setShow2FNotificationDialog(true);
          setShowClap(false);
        } else if (response.userType === "STUDENT") {
          addTokenFunc(response, usernameOrEmail);
        }
       

      })
      .catch(() => {
        openSnackbar("Invalid Login credentials", "error");
      });
  };

  const handleEnable2FA = () => {
    localStorage.setItem(ACCESS_TOKEN, apiResponse.accessToken);
    modifyUser2FA(true)
      .then(() => {
        setOTPVerification(apiResponse.alreadySetUp);
        if (apiResponse.alreadySetUp) {
          setShowQr(false);
          setIsFirstTime(false);
        } else {
          setShowQr(true);
          setShowClap(false);
          setIsFirstTime(true);
          loadFactor(usernameOrEmail);
        }
        setShow2FNotificationDialog(false);
      })
      .catch((error) => {
        openSnackbar(error.message, "error");
        setShowClap(false);
      });
  };

  const handleDoneScanning = () => {
    sendDoneWith(usernameOrEmail);
    setShowClap(true);
    setShowQr(false);
    setIsFirstTime(false);
  };

  const handleCancel = () => {
    setShowQr(false);
    setShowClap(false);
    setShow2FNotificationDialog(false);
    setIsFirstTime(false);
    setOTPVerification(false);
  };

  const daysLeft =
    differenceInDays(
      new Date(apiResponse?.initialDateRelease2fA ?? ""),
      new Date()
    ) + 1;

  return (
    <BasicLayout image={bgImage}>
      <MDBox mx={1} mt={-3} p={1} mb={1} textAlign="center">
        <div
          style={{
            borderRadius: "2px",
            overflow: "hidden",
            boxShadow:
              "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
            webkitBackdropFilter: "blur(8px)",
            backdropFilter: " blur(8px)",
            paddingBottom: next ? "10px" : 0,
            background: "rgba(0,0,0,0.4)",
            textAlign: "center",
            border: "1px solid #333",
          }}
        >
          {!isFirstTime && (
            <div
              style={{
                background: "rgba(255,255,255,1)",
                padding: "5px",
                // borderBottom:"4px solid #b5004f"
              }}
            >
              <img src={nobg} style={{ maxWidth: "100%" }} alt="logo" />
              {props.reason==="anotherBrowser"?
                    <div style={{background:"white", 
                    paddingTop:"5px",
                    marginTop:"5px",
                    fontSize:"14px",
                    borderTop:"black solid 1px",
                    color:"black", textAlign:"left"}}>
<small>
⚠️ You have been logged out because your account is logged in on another device or browser.
                       Please log in again if you need to use it here.
                       </small>
                    </div>:null}
            </div>
          )}
          <div style={{ textAlign: "left", padding: "10px" }}>
            {!next ? (
              <Grid container spacing={2}>
                <Grid item xs={9} sm={9} md={9} mt={2}>
                  <form onSubmit={handleSubmitUserName}>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                      <TextField
                        value={usernameOrEmail}
                        fullWidth
                        autoFocus
                        variant="standard"
                        onChange={(event) =>
                          setUsernameOrEmail(event.target.value)
                        }
                        type="text"
                        label="Email or Username..."
                        id="usernameOrEmail"
                        name="usernameOrEmail"
                      />
                    </ThemeProvider>
                  </form>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  {showClap ? (
                    <div style={{ marginTop: "40px" }}>
                      <ClapSpinner
                        loading
                        size={20}
                        // style={{position: "fixed"}}
                        frontColor="#FFFFFF"
                      />
                    </div>
                  ) : (
                    <div>
                      {showTimer ? null : (
                        <Button
                          style={{ marginTop: "40px" }}
                          onClick={(event) => handleSubmitUserName(event)}
                          color="white"
                          simple
                          size="lg"
                          block
                        >
                          Next <ArrowForwardIos />
                        </Button>
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
            ) : (
              <form onSubmit={handleLogin}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    {showQr ? (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "left",
                          fontSize: "14px",
                          marginBottom: "10px",
                          color: "white",
                        }}
                      >
                        {isFirstTime ? (
                          <Typography variant="body2">
                            Use an authenticator app to scan the QR code below
                            or enter the code below the QR code if the scan
                            fails, then click on the &quot;Done Scanning&quot;
                            button to proceed
                          </Typography>
                        ) : (
                          <Typography variant="body2">
                            Enter the One Time Password from the authenticator
                            app below for username:{" "}
                            <strong>{usernameOrEmail}</strong>
                          </Typography>
                        )}
                      </div>
                    ) : (
                      <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                          className="login"
                          variant="standard"
                          value={usernameOrEmail}
                          margin="dense"
                          fullWidth
                          onChange={(event) =>
                            setUsernameOrEmail(event.target.value)
                          }
                          type="text"
                          label="Email or Username..."
                          id="usernameOrEmail"
                          name="usernameOrEmail"
                        />
                      </ThemeProvider>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={isFirstTime ? 12 : 9}
                    sm={isFirstTime ? 12 : 9}
                    md={isFirstTime ? 12 : 9}
                  >
                    {showQr && isFirstTime && (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "white",
                            // backgroundImage: "linear-gradient(to right, #FFF , #2767b9)",
                          }}
                        >
                          <img
                            alt="qr"
                            style={{ width: 200, height: 200 }}
                            src={twoFactorObject?.qrCode}
                          />
                        </div>
                        <div
                          style={{
                            padding: "10px",
                            marginTop: "10px",
                            color: "white",
                            fontSize: 14,
                          }}
                        >
                          <b>{twoFactorObject?.mfaCode}</b>
                        </div>
                      </div>
                    )}

                    {!showQr && !isFirstTime && !showOTPVerification && (
                      <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                          type="password"
                          variant="standard"
                          margin="normal"
                          label="Password *"
                          fullWidth
                          autoFocus
                          name="password"
                          autoComplete="current-password"
                          id="standard-password-input"
                          onChange={(event) => setPassword(event.target.value)}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    )}
                  </Grid>
                  {showOTPVerification && (
                    <Grid item xs={8} sm={8} md={8}>
                      <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                         onKeyDown={(ev) => {
                          // console.log(`Pressed keyCode ${ev.key}`);
                          if (ev.key === 'Enter') {
                            // Do code here
                            tokenVerification()
                            ev.preventDefault();
                          }
                        }}
                          inputRef={input => input && input.focus()}
                          autoFocus 
                         ref={chartRef}
                          variant="standard"
                          value={otp}
                          margin="dense"
                          style={{ color: "white" }}
                          fullWidth
                          onChange={(event) => setOTP(event.target.value)}
                          type="text"
                          label="Enter OTP here"
                          id="otp"
                          name="otp"
                        />
                      </ThemeProvider>
                    </Grid>
                  )}
                  {!showTimer ? (
                    <Grid
                      item
                      xs={isFirstTime ? 6 : 3}
                      sm={isFirstTime ? 6 : 3}
                      md={isFirstTime ? 6 : 3}
                    >
                      {showQr && isFirstTime && (
                        <MDButton
                          fullWidth
                          size="small"
                          color="primary"
                          disabled={isLoading}
                          // style={{ right: 0, marginRight: 0 }}
                          onClick={handleDoneScanning}
                        >
                          {isLoading ? (
                            <ClapSpinner
                              loading={isLoading}
                              size={20}
                              // style={{position: "fixed"}}
                              frontColor="#FFFFFF"
                            />
                          ) : (
                            "Done Scanning"
                          )}
                        </MDButton>
                      )}
                      {showOTPVerification && (
                        <Button
                          color="white"
                          simple
                          size="lg"
                          block
                          style={{ marginTop: "40px" }}
                          onClick={tokenVerification}
                        >
                          Verify OTP
                        </Button>
                      )}
                      {!showOTPVerification && (
                        <Button
                          style={{ marginTop: "40px" }}
                          onClick={(event) => handleLogin(event)}
                          color="white"
                          simple
                          size="lg"
                          block
                        >
                          Login
                        </Button>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12} />
                  )}
                  {showQr && !isFirstTime && (
                    <Grid item xs={12} sm={12} md={12} mt={2}>
                      <Button
                        onClick={handleCancel}
                        color="white"
                        fullWidth
                        size="lg"
                        simple
                      >
                        <ArrowBack /> Back to login form
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <button
                  type="submit"
                  style={{ display: "none" }}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </form>
            )}
            {isFirstTime ? (
              <Grid container style={{ marginTop: "12px" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <div
                    style={{
                      color: "white",
                      fontSize: 12,
                      borderBottom: "1px dotted #FFF",
                      borderTop: "1px dotted #FFF",
                      textAlign: "center",
                    }}
                  >
                    2F Auth Apps
                  </div>
                </Grid>

                <Grid item xs={5} sm={5} md={5} style={{ marginTop: "12px" }}>
                  <a
                    href="https://authenticator.cc/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    <div style={{ color: "white", fontSize: 12 }}>
                      Desktop browser extension
                    </div>
                  </a>
                </Grid>

                <Grid item xs={3} sm={3} md={3} style={{ marginTop: "12px" }}>
                  <a
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.twofasapp"
                    target="_blank"
                  >
                    <div style={{ color: "white", fontSize: 12 }}>
                      Android App
                    </div>
                  </a>
                </Grid>

                <Grid item xs={4} sm={4} md={4} style={{ marginTop: "12px" }}>
                  <a
                    rel="noreferrer"
                    href="https://apps.apple.com/us/app/2fauth/id1505207634"
                    target="_blank"
                  >
                    <div style={{ color: "white", fontSize: 12 }}>IOS App</div>
                  </a>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ marginTop: "12px" }}
                >
                  <a
                    rel="noreferrer"
                    href="https://helpdesk.ucu.ac.ug/kb/faq.php?id=101"
                    target="_blank"
                  >
                    <div style={{ color: "white", fontSize: 12 }}>
                      Need 2-Factor help? Click here
                    </div>
                  </a>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </div>
        {!isFirstTime && (
          <div
            style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
          >
            <Link
              style={{ color: "#CCC", marginBottom: "10px", fontSize: "small" }}
              to="/password-reset-request"
            >
              Forgot Username Or Password?
            </Link>
          </div>
        )}
      </MDBox>

      {show2FNotificationDialog && (
        <Dialog open={show2FNotificationDialog} fullScreen>
          <DialogContent>
            <Grid container justifyContent="center" spacing={3}>
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                justifyContent="center"
                display="flex"
              >
                <Box component="img" src={ucuLogo} height={70} />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <Typography align="center" variant="h4" fontWeight="normal">
                  Two-factor authentication (2FA) <br />
                  is required for your Alpha account
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #E1E1F1",
                    background: "#F6F8FA",
                  }}
                  ml={-4}
                  px={5}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    mt={2}
                    justifyContent="center"
                    display="flex"
                  >
                    {apiResponse?.url !== null ? (
                      <Avatar
                        alt="Profile Picture"
                        src={apiResponse?.url}
                        sx={{ width: 60, height: 60 }}
                      />
                    ) : (
                      profilePictureFallBack(apiResponse?.fullName)
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mt={1}>
                    <Typography
                      align="center"
                      variant="body1"
                      fontWeight="bold"
                    >
                      {apiResponse?.fullName ?? usernameOrEmail.split("@")[0]}
                    </Typography>

                    <Typography
                      align="center"
                      variant="body2"
                      display="block"
                      mt={-1}
                    >
                      {usernameOrEmail}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} mt={2}>
                    <Alert severity="info" sx={{ border: "1px solid #A8DAFF" }}>
                      <AlertTitle>This will only take a minute</AlertTitle>
                      Enable 2FA now. You'll be able to continue on with your
                      work right after.
                    </Alert>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} mt={2}>
                    <Typography align="left" variant="body2">
                      All UCU staff must have two-factor authentication (2FA),
                      which increases account security by requiring more than
                      just a password to log in. To learn how to set up 2FA for
                      your Alpha account, click&nbsp;
                      <a
                        style={{ fontWeight: "bold" }}
                        href="https://helpdesk.ucu.ac.ug/kb/faq.php?id=101"
                        target="_blank"
                        rel="noreferrer"
                      >
                        this link.
                      </a>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    sm={7}
                    md={7}
                    justifyContent="center"
                    display="flex"
                    my={3}
                  >
                    <MDButton
                      color="success"
                      size="large"
                      fullWidth
                      onClick={handleEnable2FA}
                    >
                      Enable 2FA now
                    </MDButton>
                  </Grid>
                </Grid>
                {daysLeft > 0 && (
                  <Grid container ml={-4} mt={2.5} justifyContent="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography align="left" variant="body2">
                        You have&nbsp;
                        <strong>
                          {daysLeft}
                          &nbsp;days
                        </strong>
                        &nbsp;left to enable 2FA. Take action now to avoid
                        additional interruptions.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      justifyContent="center"
                      display="flex"
                      my={2.5}
                    >
                      <MDButton
                        color="light"
                        size="large"
                        fullWidth
                        sx={{ border: "1px solid #E1E1F1" }}
                        disabled={showClap}
                        onClick={() =>
                          addTokenFunc(apiResponse, usernameOrEmail)
                        }
                      >
                        {showClap ? (
                          <div>
                            <ClapSpinner
                              loading
                              size={20}
                              // style={{position: "fixed"}}
                              frontColor="#E1E1F1"
                            />
                          </div>
                        ) : (
                          "Remind me next time"
                        )}
                      </MDButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </BasicLayout>
  );
}

export default Basic;
