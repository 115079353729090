// @mui material components
import Card from "@mui/material/Card";
import { useState, useEffect, useCallback } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

// Images
import bgImage from "assets/img/loginBg3.png";
import nobg from "assets/img/uculogotousenobg.png";
import { Grid } from "@mui/material";

import { checkToken, updatePassword } from "util/APIUtils";
import { verifyLength, verifyPassword } from "constants/methodConstants";
import { useSnackbar } from "notistack";

const TEST_SITE_KEY = "6Le5Tr4aAAAAAF3vZQWQEhrJ8mljqMm-svtlMXTb";
function Cover() {
  const [notRobot, setnotRobot] = useState(false);

  const [mailState, setmailState] = useState(false);

  // const [msg, setmsg] = useState("");
  // const [show, setshow] = useState(false);
  // const [colorSnack, setcolorSnack] = useState("");

  const [showResponse, setshowResponse] = useState(false);

  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const [passwordState, setpasswordState] = useState(false);
  const [confirmPasswordState, setconfirmPasswordState] = useState(false);

  const [passwordSuccessState, setpasswordSuccessState] = useState(false);
  const [confirmPasswordSuccessState, setconfirmPasswordSuccessState] =
    useState(false);

  const [userId, setuserId] = useState("");
  const [tokenId, settokenId] = useState("");
  const [tokenStatus, settokenStatus] = useState("");
  const [username, setusername] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const openSnackbar = useCallback(
    (message, color) => {
      enqueueSnackbar(message, { variant: color });
    },
    [enqueueSnackbar]
  );

  const passwordRequirements = [
    "One special characters e.g ( !@#% )",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <MDTypography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const loadCheckToken = useCallback(
    (token) => {
      const loginRequest = {
        token,
      };

      checkToken(loginRequest)
        .then((response) => {
          setuserId(response.id);
          settokenId(response.tokenId);
          settokenStatus(response.tokenStatus);
          setusername(response.userName);
        })
        .catch((error) => {
          console.log("password-reset === ", error);
          openSnackbar(error.message, "error");
        });
    },
    [openSnackbar]
  );

  useEffect(() => {
    const query = window.location.href;
    const neededPart = query.split("password-reset/")[1];

    // console.log("props =====> ",query)

    if (query !== "") {
      loadCheckToken(neededPart);
    }

    // alert(props)
  }, [loadCheckToken]);

  const handleChangeRoboot = (response) => {
    // alert(response)
    if (response) {
      setnotRobot(true);
    } else {
      // alert("no respo")
    }
    //  alert("successfully")
  };

  const handleMyChange = () => {
    setnotRobot(false);
  };

  const expiredCallback = () => {
    setnotRobot(false);
  };

  function handleSubmit() {
    const loginRequest = {
      id: userId,
      tokenId,
      newPassword: password,
    };

    // alert(JSON.stringify(loginRequest));

    if (passwordSuccessState && confirmPasswordSuccessState) {
      updatePassword(loginRequest)
        .then((response) => {
          if (response.success === false) {
            openSnackbar(response.message, "error");
          } else {
            openSnackbar(response.message, "success");

            // setemailSent(true)
            setshowResponse(true);
          }
        })
        .catch((error) => {
          openSnackbar(error.message, "error");
        });
    } else {
      openSnackbar("Invalid Password", "error");
    }
  }

  function compare(string1, string2) {
    if (string1 === string2) {
      return true;
    }
    return false;
  }

  function change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (verifyLength(event.target.value, stateNameEqualTo)) {
          setmailState(false);
        } else {
          setmailState(true);
        }
        break;
      case "password":
        if (verifyPassword(event.target.value)) {
          setpassword(event.target.value);
          setpasswordState(false);
          setpasswordSuccessState(true);
        } else {
          setpassword(event.target.value);
          setpasswordSuccessState(false);
          setpasswordState(true);
        }
        break;
      case "equalTo":
        if (compare(event.target.value, password)) {
          setconfirmPasswordSuccessState(true);
          setconfirmPassword(event.target.value);
          setconfirmPasswordState(false);
        } else {
          setconfirmPassword(event.target.value);
          setconfirmPasswordState(true);
          setconfirmPasswordSuccessState(false);
        }
        break;

      default:
        break;
    }
  }

  return (
    <CoverLayout
      coverHeight="45vh"
      image={bgImage}

      // style={{marginTop:"-20px"}}
    >
      {/* <MDSnackbar
        color={colorSnack}
        icon="notifications"
        title="System Notification"
        content={msg}
        autoHideDuration={4000}
        open={show}
        close={toggleSnackbar}
        onClose={toggleSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      /> */}

      <Card style={{ marginTop: "-120px", marginBottom: "30px" }}>
        <MDBox
          style={{ borderBottom: "1px solid #CCC" }}
          variant="gradient"
          bgColor="none"
          borderRadius="lg"
          //   coloredShadow="dark"
          mx={2}
          // mt={-3}
          py={2}
          mb={0}
          textAlign="center"
        >
          <div
            style={{
              background: "rgba(255,255,255,1)",
              padding: "5px",
              // borderBottom:"4px solid #b5004f"
            }}
          >
            {/* <p style={{color:"#b5004f"}}>*** Happy Holidays ***</p> */}
            <img
              src={nobg}
              // src={first.color === "#000" ? imageToUse : logodark}
              style={{ maxWidth: "100%" }}
              alt="logo"
              // className={classes.loginlogo}
            />
          </div>
          {/* <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography> */}
        </MDBox>

        {!showResponse ? (
          <MDBox pt={4} pb={3} px={3}>
            {tokenStatus === 1 ? (
              <MDBox pt={4} pb={3} px={3}>
                <MDBox mt={0} mb={1}>
                  <FormControl>
                    <MDBox mt={0} mb={1}>
                      <MDTypography variant="h5">
                        {" "}
                        Reseting Password for : <u> {` ${username}`}</u>
                      </MDTypography>
                    </MDBox>
                  </FormControl>
                </MDBox>

                <MDBox mt={0} mb={2} />

                <MDBox mb={1}>
                  <MDTypography variant="h6">
                    Password requirements
                  </MDTypography>
                </MDBox>
                <MDBox mb={1}>
                  <MDTypography variant="body2" color="text">
                    Please follow this guide for a strong password
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                >
                  <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                    {renderPasswordRequirements}
                  </MDBox>
                </MDBox>

                <MDBox mt={0} mb={2} />
                <MDBox component="form" role="form">
                  <MDBox mb={4}>
                    <MDInput
                      type="password"
                      label="Enter Password e.g Alpha@96"
                      variant="outlined"
                      fullWidth
                      value={password}
                      onChange={(event) => change(event, mailState, "password")}
                      error={passwordState}
                      success={passwordSuccessState}
                    />
                  </MDBox>

                  <MDBox mb={4}>
                    <MDInput
                      type="password"
                      label="Confirm Password"
                      variant="outlined"
                      fullWidth
                      value={confirmPassword}
                      onChange={(event) => change(event, mailState, "equalTo")}
                      error={confirmPasswordState}
                      success={confirmPasswordSuccessState}
                    />
                  </MDBox>

                  <MDBox mt={6} mb={1} alignItems>
                    <ReCAPTCHA
                      sitekey={TEST_SITE_KEY}
                      render="explicit"
                      onChange={handleChangeRoboot}
                      onloadCallback={handleMyChange}
                      onExpired={expiredCallback}
                      type="images"
                    />
                  </MDBox>

                  <MDBox mt={6} mb={1} sx={{ backgroundColor: "black" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5} lg={4}>
                        <Link
                          style={{ color: "#CCC", fontSize: "small" }}
                          to="/"
                        >
                          <MDButton variant="gradient" color="light">
                            Back To Login Page
                          </MDButton>
                        </Link>
                      </Grid>{" "}
                      <Grid item xs={12} md={2} lg={4} />
                      <Grid item xs={12} md={5} lg={4}>
                        {notRobot && tokenStatus === 1 ? (
                          <MDButton
                            onClick={() => handleSubmit()}
                            variant="gradient"
                            color="success"
                            style={{ fontSize: "small" }}
                          >
                            RESET Password
                          </MDButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox pt={4} pb={3} px={3}>
                <MDBox mt={0} mb={2}>
                  <FormLabel mb={2} id="demo-row-radio-buttons-group-label">
                    <b color="#b5004f">Invalid or Broken Token</b>
                  </FormLabel>
                </MDBox>
                <MDTypography
                  fontWeight="medium"
                  type="subtitle-1"
                  display="block"
                  variant="button"
                  my={1}
                >
                  <p style={{ textAlign: "center" }}>
                    Opps! The link may be broken or already used.
                  </p>
                  <p style={{ textAlign: "center" }}>
                    {" "}
                    Please make sure that you copied the link correctly or
                    request for another token from
                    <Link
                      to="/password-reset-request"
                      sx={{ color: "red", fontSize: "15px" }}
                    >
                      here
                    </Link>
                    .
                  </p>
                </MDTypography>

                <MDBox mt={6} mb={1} sx={{ backgroundColor: "black" }}>
                  <Link
                    style={{
                      color: "#CCC",
                      marginBottom: "10px",
                      fontSize: "small",
                    }}
                    to="/"
                  >
                    <MDButton variant="gradient" color="light">
                      Back To Login Page
                    </MDButton>
                  </Link>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        ) : (
          <MDBox pt={4} pb={3} px={3}>
            <MDBox mt={0} mb={2}>
              <FormLabel mb={2} id="demo-row-radio-buttons-group-label">
                Operation Successfull
              </FormLabel>
            </MDBox>
            <MDTypography
              fontWeight="medium"
              type="subtitle-1"
              display="block"
              variant="button"
              my={1}
            >
              <p style={{ textAlign: "center" }}>
                Your password has been updated successfully.
              </p>
              <p style={{ textAlign: "center" }}>
                Please login with your new passowrd.
              </p>
            </MDTypography>

            <MDBox mt={6} mb={1} sx={{ backgroundColor: "black" }}>
              <Link
                style={{
                  color: "#CCC",
                  marginBottom: "10px",
                  fontSize: "small",
                }}
                to="/"
              >
                <MDButton variant="gradient" color="light">
                  Back To Login Page
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
        )}
      </Card>
    </CoverLayout>
  );
}

export default Cover;
