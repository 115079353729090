import PasswordResetMgtRq from "layouts/authentication/reset-password/cover";
import SignInBasic from "layouts/authentication/sign-in/basic";
import PasswordResetMgt from "layouts/authentication/reset-password/PasswordReset";

const routes = [
  {
    route: "/",
    component: <SignInBasic />,
    key: "login",
  },
  {
    key: "password-reset-request",
    route: "/password-reset-request",
    component: <PasswordResetMgtRq />,
  },

  {
    key: "password-reset",
    route: "/password-reset/*",
    component: <PasswordResetMgt />,
  },
];

export default routes;
