/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer";

function BasicLayout({ image, children }) {
  return (
    <PageLayout>
      <DefaultNavbar
        style={{ position: "relative", zIndex: 20000 }}
        routes={pageRoutes}
        // action={{
        //   type: "external",
        // //  route: "https://creative-tim.com/product/material-dashboard-pro-react",
        // //  label: "buy now",
        // }}
        transparent
        light
      />
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(41, 106, 176, 0.7), rgba(0, 0, 0, 0.83)), url(${image})`,
          // backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          //   image &&
          //   `${linearGradient(
          //     rgba(168, 50, 66, 0.6),
          //     rgba(255, 255, 255, 0.6)
          //   )
          // }, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      {/* <BackgroundSlideshow
            images={[bgImage, bgImage2, bgImage3, bgImage4] }
          /> */}
      <div style={{ zIndex: 10, position: "relative" }}>
        <MDBox px={1} width="100%" height="100vh" mx="auto">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} sm={9} md={6} lg={5} xl={4}>
              {children}
            </Grid>
          </Grid>
        </MDBox>
      </div>
      <div style={{ zIndex: 500 }}>
        <Footer style={{ zIndex: -1 }} light />
      </div>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
